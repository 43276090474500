import Avatar from 'boring-avatars';
import Logo from './Logo';

export default function Jobs() {
  return (
    <main>

    <section className='outlinedContainer flexColumnCentered'>
    <Logo />
    </section>

    <section className='outlinedContainer flexColumnCentered minHeight'>
        <div>
            <Avatar name="Developer" colors={["#59b390", "#f0ddaa", "#e47c5d", "#e32d40", "#152b3c"]} variant="pixel"  size={80}/>
            <h2>Unity Developer Intern</h2>
            <p>If you have a strong foundation in Unity and C#, a love for gaming, desire to work, learn and grow, we’d love to hear from you!</p>
            <h3><a href='https://docs.google.com/forms/d/e/1FAIpQLSc-hwIt8Y7x9mWlKvcuDpi2rbKiKU3lmFt9n3UkDOYFfFYcYg/viewform?usp=sf_link' target='_blank' rel='noreferrer'>Apply</a></h3>
        </div>
    </section>

    <section className='outlinedContainer flexColumnCentered minHeight'>
        <div>
            <Avatar name="Artist" colors={["#59b390", "#f0ddaa", "#e47c5d", "#e32d40", "#152b3c"]} variant="pixel"  size={80}/>
            <h2>Game Artist Intern</h2>
            <p>If you excel at designing compelling characters, detailed environments, and visually engaging UI, and are excited to contribute your creativity to a growing studio, this is the perfect opportunity to showcase your skills and expand your expertise!</p>
            <h3><a href='https://docs.google.com/forms/d/e/1FAIpQLSdmQOHwPVznloyk_ABaKq_HmogilofRm_KoCDq4locEhv822w/viewform?usp=sf_link' target='_blank' rel='noreferrer'>Apply</a></h3>
        </div>
    </section>

    <section className='outlinedContainer flexColumnCentered minHeight'>
        <div>
            <Avatar name="Composer" colors={["#59b390", "#f0ddaa", "#e47c5d", "#e32d40", "#152b3c"]} variant="pixel"  size={80}/>
            <h2>Composer / Sound Engineer Intern</h2>
            <p>If you compose music for games and can create a pack of sounds we'd love to have you onboard as a part of our growing team!</p>
            <h3><a href='https://docs.google.com/forms/d/e/1FAIpQLSdLlWmbdaX_NVY7CsY9x9_oS5DehTtu302iphleRfY0KukMww/viewform?usp=sf_link' target='_blank' rel='noreferrer'>Apply</a></h3>
        </div>
    </section>

    <section className='outlinedContainer flexColumnCentered minHeight'>
        <div>
            <Avatar name="Designer" colors={["#59b390", "#f0ddaa", "#e47c5d", "#e32d40", "#152b3c"]} variant="pixel"  size={80}/>
            <h2>UX/UI Design Intern</h2>
            <p>If you’re passionate about crafting intuitive user interfaces and seamless player experiences, and you’re eager to make a real impact while enhancing your design skills, we’d love to see what you can bring to the team!</p>
            <h3><a href='https://docs.google.com/forms/d/e/1FAIpQLSdmQOHwPVznloyk_ABaKq_HmogilofRm_KoCDq4locEhv822w/viewform?usp=sf_link' target='_blank' rel='noreferrer'>Apply</a></h3>
        </div>
    </section>


    


</main>
  )
}
