import Logo from './Logo'

export default function Home() {
  return (
    <main>


<img src='./images/meatysRevengeWeb.jpg' alt="Meaty's Revenge Banner" className='heroImage' />



      <section className='outlinedContainer flexColumnCentered'>
        <Logo/>
      </section>

      <section className='text outlinedContainer '>
        <div>
          <h1>Meaty's Revenge</h1>
          <p>In a world ruled by vegetables, only one meatball dares to fight back.</p>
          <p>Something is cooking...</p>
        </div>
      </section>


      <section className='flexColumnCentered outlinedContainer darkBackground'>
        <img src='./images/splash_picky_eater.jpg' alt='Picky Eater Go! Banner' />
      </section>


      <section className='text outlinedContainer darkBackground'>
        <div>
          <h1>Picky Eater Go!</h1>
          <p>You are a Blob. And you need to eat. But only food, not anything else!</p>
          <p>Unfortunately, life is not so easy and you have to be picky to catch only the food.</p>
          <p>On the bright side, there are plenty of food and some of it is colelctible! So you can collect all the best desserts, drinks or sweets 😋</p>
          <p>Sadly enough there are some evil entities trying to make your life harder, because they are evil duh!</p>
          <p>Eat your way through the levels and avoid disgusting non-food!</p>
        </div>
      </section>




      <section className='outlinedContainer flexColumnCentered darkBackground'>
        <div>


          <video loop={true} autoPlay playsInline muted={true} className="object-cover object-center w-full h-full absolute inset-0 phone-mask"><source src="./videos/PickyEaterGo_promo_lv1-24.mp4" type="video/mp4" />Sorry. Your browser does not support the video tag.</video>
          <div className='storeIcons'>
            <img src="./images/picky_eater_go!_icon.png" alt="Picky Eater Go! store icon" className="storeIcon" />
            <div>
              <a href='https://apps.apple.com/us/app/picky-eater-go/id6451353816' target='_blank'><img src='./images/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg' alt="get on app store" className="getOnAppStore" /></a>
              <a href='https://play.google.com/store/apps/details?id=com.soamazing.games.pickyeatergo' target='_blank'><img alt='Get it on Google Play' className="getOnAppStore" src='./images/en_badge_web_generic.png' /></a>
            </div>

          </div>
        </div>
      </section>














      <section className='outlinedContainer flexColumnCentered '>
        <img src='./images/sausagedeath03.png' alt='Sausage Death Guess a Word or Die' />
      </section>


      <section className='text outlinedContainer'>
        <div>
          <h1>Guess a word or die</h1>
          <p>You know the words.</p>
          <p>Just guess them.</p>
          <p>Or someone is going to die...</p>
        </div>
      </section>

      <section className=' outlinedContainer flexColumnCentered '>
        <div>
          <video loop={true} autoPlay playsInline muted={true} className="object-cover object-center w-full h-full absolute inset-0 phone-mask"><source src="./videos/RPReplay_Final1689412279.MP4" type="video/mp4" />Sorry. Your browser does not support the video tag.</video>
          <div className='storeIcons'>
            <img src="./images/guess_a_word_or_die_store_icon.png" alt="guess a word or die store icon" className="storeIcon" />
            <div>
              <a href='https://apps.apple.com/us/app/guess-a-word-or-die/id1534154298' target='_blank'><img src='./images/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg' alt="get on app store" className="getOnAppStore" /></a>
              <a href='https://play.google.com/store/apps/details?id=com.barcelonacodeschool.guessawordordie' target='_blank'><img alt='Get it on Google Play' className="getOnAppStore" src='./images/en_badge_web_generic.png' /></a>
            </div>
          </div>
        </div>
      </section>

      {/* <section className='outlinedContainer flexColumnCentered'>
      <div >
        <p>Game Development studio</p>
        <p>Barcelona</p>
        <p><a href='mailto:gk@soamazing.games'>Get in touch</a></p>
        <a href='https://www.linkedin.com/company/105455066' target="_blank" rel="noreferrer"><img src="./images/icon-linkedin-250.png" alt="So Amazing Games on LinkedIn" className="icon" /></a>
        <a href='https://instagram.com/soamazinggames' target="_blank" rel="noreferrer"><img src="./images/_i_icon_06248_icon_062481_256.png" alt="So Amazing Games on Instagram" className="icon" /></a>
        <a href='https://twitter.com/SoAmazingGames' target="_blank" rel="noreferrer"><img src="./images/_i_icon_01789_icon_017891_256.png" alt="So Amazing Games on Twitter / X" className="icon" /></a>

      </div>
    </section> */}

    </main>
  )
}
