// import logo from './So_Amazing_Games.jpeg';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ScrollToTop from "./helpers/ScrollToTop";

import Home from './Home';
import Team from './Team';
import Jobs from './Jobs';
import Nav from './Nav';
import Footer from './Footer';

function App() {
  return (
    <div className="App">
      <Router>
        <ScrollToTop />
        <Nav />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/team" element={<Team />} />
          <Route path="/jobs" element={<Jobs />} />
        </Routes>
      </Router>
      <Footer />
    </div>
  );
}



export default App;
