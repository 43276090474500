import Avatar from 'boring-avatars';
import Logo from './Logo';

export default function Team() {
    return (
        <main>

            <section className='outlinedContainer flexColumnCentered'>
            <Logo />
            </section>

            <section className='outlinedContainer flexColumnCentered minHeight'>
                <div>
                    <Avatar name="Philipp" colors={["#59b390", "#f0ddaa", "#e47c5d", "#e32d40", "#152b3c"]} variant="beam" size={120} />
                    <h2>Philipp</h2>
                    <h3>Developer</h3>
                </div>
            </section>

            <section className='outlinedContainer flexColumnCentered minHeight'>
                <div>
                    <Avatar name="Nicollas" colors={["#59b390", "#f0ddaa", "#e47c5d", "#e32d40", "#152b3c"]} variant="beam" size={120} />
                    <h2>Nicollas</h2>
                    <h3>Developer</h3>
                </div>
            </section>

            <section className='outlinedContainer flexColumnCentered minHeight'>
                <div>
                    <Avatar name="Rustam" colors={["#59b390", "#f0ddaa", "#e47c5d", "#e32d40", "#152b3c"]} variant="beam" size={120} />
                    <h2>Rustam</h2>
                    <h3>Developer</h3>
                </div>
            </section>

            <section className='outlinedContainer flexColumnCentered minHeight'>
                <div>
                    <Avatar name="GK3000" colors={["#59b390", "#f0ddaa", "#e47c5d", "#e32d40", "#152b3c"]} variant="beam" size={120} />
                    <h2>George</h2>
                    <h3>Producer</h3>
                </div>
            </section>




        </main>
    )
}
