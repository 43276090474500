import { useState, useEffect } from 'react'
import { NavLink } from "react-router-dom";

import './Nav.css'

export default function Nav() {
    // this is a dropdown nav burger, on click opens a full screen overlay with centered nav items: Home, Team, Jobs
    const [navOpen, setNavOpen] = useState(false)
    const toggleNav = () => {
        setNavOpen(!navOpen)
    }

    return (
        <nav>
            <button onClick={toggleNav} className='navButton'>
                |||
            </button>
            {navOpen && <div className='navOverlay'>
                <button onClick={toggleNav} className='closeNavButton'>X</button>
                <ul>
                    <li onClick={toggleNav}>
                        <NavLink
                            to={"/"}>
                            Home
                        </NavLink>
                    </li>
                    <li onClick={toggleNav}>
                        <NavLink
                            to={"/team"}>
                            Team
                        </NavLink>
                    </li>
                    <li onClick={toggleNav}>
                        <NavLink
                            to={"/jobs"}>
                            Work with us
                        </NavLink>
                    </li>
                </ul>
            </div>}
        </nav>
    )
}






