import React from 'react'

export default function Footer() {
  return (
    <footer className='outlinedContainer flexColumnCentered'>
    <div >
      <p>So Amazing Games</p>
      <p>Barcelona</p>
      <p><a href='mailto:gk@soamazing.games'>Send an email</a></p>
      <a href='https://www.linkedin.com/company/105455066' target="_blank" rel="noreferrer"><img src="./images/icon-linkedin-250.png" alt="So Amazing Games on LinkedIn" className="icon" /></a>
      <a href='https://instagram.com/soamazinggames' target="_blank" rel="noreferrer"><img src="./images/_i_icon_06248_icon_062481_256.png" alt="So Amazing Games on Instagram" className="icon" /></a>
      <a href='https://twitter.com/SoAmazingGames' target="_blank" rel="noreferrer"><img src="./images/_i_icon_01789_icon_017891_256.png" alt="So Amazing Games on Twitter / X" className="icon" /></a>
    <p style={styles.copyright}>© {(new Date).getFullYear()} All Rights reserved </p>
    </div>
  </footer>
  )
}

const styles = {
  copyright: {
    marginTop: '4em',
  }
}
