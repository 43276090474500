import { NavLink } from "react-router-dom";

export default function Logo() {
    return (
        <>
            <NavLink
                to={"/"}>
                <img src='./images/so_amazing_games_logo.jpg' className="App-logo" alt="logo" />
            </NavLink>
            <p>Game Development Studio</p>
        </>
    )
}
